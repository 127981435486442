




















import {Component, Vue} from 'vue-property-decorator'
import {currentOrder} from '@/api/courseOrder'
import {CourseOrderDto} from '@/dto/CourseOrderDto';
import {queryOrderAsync} from '@/api/webchatpay';
import {paymentOrderAsync} from '@/api/webchatpay'

// @ts-nocheck
@Component({})
export default class CourseBuyOrder extends Vue {
  private courseOrderDto: CourseOrderDto = {
    courseId: '',
    courseTime: 0,
    discountPrice: 0,
    orderState: 0,
    price: 0,
    remark: ''
  };

  private orderType = 'course'
  private loading = false

  get orderId() {
    return this.$route.query.orderId;
  }

  private async activated() {
    await this.getCurrentOrder()
  }

  private async getCurrentOrder() {
    this.courseOrderDto = await currentOrder(this.orderId)
  }

  private async handleSubmitOrder() {
    const openid = localStorage.getItem('Keep_OpenId')
    if (this.courseOrderDto.discountPrice > 0 && openid && this.orderId) {
      this.loading = true
      const result = await paymentOrderAsync(openid, this.orderId, this.orderType).then().catch(() => {
        this.loading = false
      })
      if (result) {
        this.jsApiCall(result)
      }
    }
  }

  private jsApiCall(payJson: any) {
    const that = this
    const wx = require('weixin-js-sdk')
    wx.config({
      appId: payJson.appId,
      timestamp: payJson.timeStamp,
      nonceStr: payJson.nonceStr,
      signature: payJson.paySign,
      jsApiList: ['chooseWXPay']
    })
    wx.ready(() => {
      wx.chooseWXPay({
        timestamp: payJson.timeStamp,
        nonceStr: payJson.nonceStr,
        package: payJson.package,
        signType: payJson.signType,
        paySign: payJson.paySign,
        success(res: any) {
          if (res.errMsg === 'chooseWXPay:ok') {
            queryOrderAsync(payJson.orderCode, that.orderType).then(() => {
              that.$router.replace('/user/courses')
            })
          }
        },
        cancel() {
          this.$toast('用户取消支付~')
        },
        // 支付失败回调函数
        fail() {
          this.$toast('支付失败~')
        }
      })
      this.loading = false
    })
  }
}
