import request from '@/utils/request'
import {CourseOrderDto} from '@/dto/CourseOrderDto';

const url = '/keep-fit/courseOrder'

// 生成订单
export function makeOrder(orderData: any): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}`,
      method: 'post',
      data: orderData
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

// 获取当前未付款订单
export function currentOrder(orderId: string | Array<string | null>): any {
  return new Promise<CourseOrderDto>((resolve, reject) => {
    request({
      url: `${url}/current`,
      method: 'get',
      params: {
        orderId
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function getOrders(): any {
  return new Promise<CourseOrderDto[]>((resolve, reject) => {
    request({
      url: `${url}/orders`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function modifyOrders(id: string | Array<string | null>): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}/${id}/order-course-state`,
      method: 'post'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

// 用户获取私教订单
export function getPtOrders(): any {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}/pt-orders`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

// 用户获取私教订单
export function getPtByCoachOrders(): any {
  return new Promise<any>((resolve, reject) => {
    request({
      url: `${url}/pt-get-orders`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function modifyOrderCourseTime(id: string | Array<string | null>, courseTime: string): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}/${id}/free-class?courseTime=${courseTime}`,
      method: 'post'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

